import { List, Datagrid, TextField, DateField, UrlField, EditButton, BooleanField } from "react-admin";

export const ProjectList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
      	<TextField source="id" />
      	<TextField source="title" />
			  <TextField source="description" />
      	<UrlField source="link" />
			  <BooleanField source="visible"/>
	      <EditButton/>
      </Datagrid>
    </List>
  );
};