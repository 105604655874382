import React from 'react';
import { Admin,fetchUtils,Resource } from 'react-admin';
import crudProvider from 'ra-data-nestjsx-crud';
import { ProjectList } from './components/ProjectList';
import { ProjectEdit } from './components/ProjectEdit';
import { ProjectCreate } from './components/ProjectCreate';
import authProvider from './authProvider';
import { getAccessToken } from './helpers/getToken';

const fetchJson = (url: string, options: fetchUtils.Options = {}) => {
  const customHeaders = (options.headers ||
    new Headers({
        Accept: 'application/json',
    })) as Headers;
customHeaders.set('Authorization', `Bearer ${getAccessToken()}`);
options.headers = customHeaders;
return fetchUtils.fetchJson(url, options);
}

const dataProvider = crudProvider(`${process.env.REACT_APP_BACKEND_HOST}`, fetchJson);

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} requireAuth={true}>
    <Resource name="projects" list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />
  </Admin>
);
export default App;