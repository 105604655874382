import { Create, SimpleForm, TextInput, BooleanInput, ImageField, ImageInput } from "react-admin";

export const ProjectCreate = () => (
  <Create title={'Creating Project'} redirect={'/'}>
    <SimpleForm>
      <TextInput source="title" required/>
      <TextInput source="description" required multiline rows={2}/>
      <TextInput source="link" required />
		<BooleanInput source="visible" defaultValue={true} />
    </SimpleForm>
  </Create>
);
