import { AuthProvider } from "react-admin";

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_BACKEND_HOST}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({username, password}),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
    .then(response => {
      if (response.status <200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(token => {
      localStorage.setItem('token', token.accessToken);
    })
    .catch(error => {throw new Error(error)});
  },

  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },

  checkError: ({ status }) => {
    return status === 401 || status === 403
    ? Promise.reject() : Promise.resolve()
  },

  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  getPermissions: () => Promise.reject(""),
};

export default authProvider;
