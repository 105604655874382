import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

export const ProjectEdit = () => (
  <Edit title={'Editing Project'}>
    <SimpleForm>
      <TextInput source="id" disabled/>
      <TextInput source="title" />
      <TextInput source="description" multiline rows={2}/>
      <TextInput source="link" />
      <BooleanInput source="visible" defaultValue={true} />
    </SimpleForm>
  </Edit>
);
